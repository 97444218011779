import {
  CompanyResource,
  FeatureFlag,
  ManagerListItem,
  Order,
  PermissionsResource,
  PermissionsScope,
  StatementResource,
  Statement as StatementType,
} from '@sbiz/business';
import { ObjectNestedProperty } from '@sbiz/common';
import { Json } from '@sbiz/util-mongodb';

import { Benefit } from './benefit';
import { EmployeeInvitation } from './employee-invitation';
import { GiftCard } from './gift-card';
import { Profile } from './profile';
import { Statement } from './statement';

export type ApiData = string | object;

export type ApiRecords = {
  benefit: Benefit;
  benefitAdmin: Benefit;
  company: Json<CompanyResource>;
  employee: EmployeeInvitation;
  featureFlag: FeatureFlag;
  giftCard: GiftCard;
  manager: Json<ManagerListItem>;
  monthlyStatement: Json<StatementResource>;
  order: Order;
  orderAdmin: Order;
  permissions: Json<PermissionsResource>;
  permissionsAdmin: Json<PermissionsResource>;
  profile: Profile;
  profileAdmin: Profile;
  purchases: Json<StatementType>;
  statement: Statement;
};
export type ApiRecord<T extends ResourceType> = T extends keyof ApiRecords ? ApiRecords[T] : string | object;

export const API_RESOURCES_DEFINITION = {
  addresses: { path: 'v1/addresses' },
  auth: { path: 'v1/auth' },
  benefit: { deletionField: 'smoodBusinessBenefit.deletedAt', path: 'v1/benefits', permissionsScope: 'benefits' },
  benefitAdmin: {
    deletionField: 'smoodBusinessBenefit.deletedAt',
    path: 'v1/admin/benefits',
    permissionsScope: 'adminOrders',
  },
  checkDistribution: { path: 'v1/checks/distributions', permissionsScope: 'smoodChecks' },
  check: { path: 'v1/checks', permissionsScope: 'smoodChecks' },
  company: { path: 'v1/companies' },
  companyAdmin: { path: 'v1/admin/companies', permissionsScope: 'adminCompanies' },
  compensationAdmin: { path: 'v1/admin/compensations', permissionsScope: 'adminCompensations' },
  compensation: { path: 'v1/compensations', permissionsScope: 'accounting' },
  costCenter: { path: 'v1/companies/cost-centers', permissionsScope: 'companyCostCenter' },
  costCenterAdmin: { path: 'v1/admin/companies/cost-centers', permissionsScope: 'adminCompanies' },
  datatrans: { path: 'v1/datatrans' },
  datatransAdmin: { path: 'v1/admin/datatrans', permissionsScope: 'adminDatatrans' },
  employee: { path: 'v1/employees', permissionsScope: 'employees' },
  employeeInvitation: { path: 'v1/employees/invitations', permissionsScope: 'employees' },
  employeeInvitationAdmin: { path: 'v1/admin/employees/invitations', permissionsScope: 'adminEmployees' },
  featureFlag: { path: 'v1/feature-flags' },
  feedback: { path: 'v1/user-feedbacks' },
  files: { path: 'v1/files' },
  giftCard: { path: 'v1/gift-cards', permissionsScope: 'giftCards' },
  giftCardAdmin: { path: 'v1/admin/gift-cards', permissionsScope: 'adminGiftCardRequests' },
  invoice: { path: 'v1/invoices', permissionsScope: 'accounting' },
  invoiceAdmin: { path: 'v1/admin/invoices', permissionsScope: 'adminOpenInvoices' },
  manager: {
    companyField: 'companies',
    deletionField: 'permissions.deletedAt',
    path: 'v1/managers',
    permissionsScope: 'managers',
  },
  managerAdmin: {
    companyField: 'companies',
    deletionField: 'permissions.deletedAt',
    path: 'v1/admin/managers',
    permissionsScope: 'adminPermissions',
  },
  managerInvitation: { path: 'v1/manager-invitations', permissionsScope: 'managers' },
  managerInvitationAdmin: { path: 'v1/admin/manager-invitations', permissionsScope: 'adminPermissions' },
  monthlyStatement: { path: 'v1/statements', permissionsScope: 'accounting' },
  monthlyStatementAdmin: { path: 'v1/admin/statements', permissionsScope: 'adminOpenInvoices' },
  offer: { path: 'v1/offers', permissionsScope: 'adminCompanies' },
  order: { path: 'v1/orders', permissionsScope: 'orders' },
  orderAdmin: { path: 'v1/admin/orders' },
  permissions: { path: 'v1/managers/permissions', permissionsScope: 'permissions' },
  permissionsAdmin: { path: 'v1/admin/managers/permissions', permissionsScope: 'adminPermissions' },
  profile: { path: 'v1/profiles', permissionsScope: 'profiles' },
  profileAdmin: { path: 'v1/admin/profiles', permissionsScope: 'adminOrders' },
  purchases: { path: 'v1/statements', permissionsScope: 'purchases' },
} as const satisfies Record<
  string,
  { companyField?: string; deletionField?: string; path: string; permissionsScope?: PermissionsScope }
>;
type ApiResources = typeof API_RESOURCES_DEFINITION;
export const API_RESOURCES: Readonly<{ [P in ResourceType]: ResourceDefinition }> = API_RESOURCES_DEFINITION;

export type ResourceCompanyField = ObjectNestedProperty<ApiResources, 'companyField'>;
export type ResourceDeletionField = ObjectNestedProperty<ApiResources, 'deletionField'>;
export type ResourcePath = ObjectNestedProperty<ApiResources, 'path'>;
export type ResourceType = keyof ApiResources;
export type ResourceDefinition = {
  companyField?: ResourceCompanyField;
  deletionField?: ResourceDeletionField;
  path: ResourcePath;
  permissionsScope?: PermissionsScope;
};
