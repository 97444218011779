import { getObjectFromEntries, getTotal, isInSet, roundHalfToEven, toCentimes } from '@sbiz/util-common';
import { capitalize } from '@sbiz/util-strings';

import {
  ADMIN_PERMISSIONS_NAME,
  Chargeable,
  CHARGEABLE_AMOUNT_KEYS,
  Order,
  OrderBenefit,
  PERMISSIONS_SCOPES,
  PermissionsAction,
  PermissionsResource,
  PermissionsScope,
  STATEMENT_PURCHASE_AMOUNT_KEYS,
  StatementPurchaseAmounts,
} from './types';

const PERMISSIONS_SCOPE_SET = new Set(PERMISSIONS_SCOPES);
const ADMIN_PERMISSIONS_SCOPE_PREFIX = 'admin';

export function getAdjustedOrderBenefits(benefits: OrderBenefit[], benefitUse: number) {
  let remainder = benefitUse;

  return benefits.map((benefit) => {
    const amount = Math.min(remainder, benefit.amount);
    remainder = Math.max(remainder - amount, 0);
    return { ...benefit, amount };
  });
}

export function getAdminPermissionsScope(scope: PermissionsScope) {
  if (scope.startsWith(ADMIN_PERMISSIONS_SCOPE_PREFIX)) {
    return null;
  }

  const adminScope = `${ADMIN_PERMISSIONS_SCOPE_PREFIX}${capitalize(scope)}`;
  return isInSet(adminScope, PERMISSIONS_SCOPE_SET) ? adminScope : null;
}

export function getChargeableTotalAmounts(items: Chargeable[], options?: { isRounded?: boolean }) {
  return getTotalAmounts(items, CHARGEABLE_AMOUNT_KEYS, options);
}

export function getTotalAmounts<T extends Record<string, number>>(
  items: T[],
  keys: Readonly<(keyof T)[]>,
  options?: { isRounded?: boolean },
) {
  const amounts = getObjectFromEntries(keys.map((key) => [key, 0]));

  for (const item of items) {
    for (const key of keys) {
      amounts[key] += item[key];
    }
  }

  if (options?.isRounded) {
    for (const key of keys) {
      amounts[key] = roundHalfToEven(amounts[key]);
    }
  }

  return amounts;
}

export function getOrderBenefitUse(order: Order) {
  const benefitUsage = getTotal(order.benefits);

  if (benefitUsage) {
    const totalOrder = getTotalOrder(order);
    return Math.min(benefitUsage, totalOrder);
  }

  return benefitUsage;
}

export function getOrderEndDate({
  completedAt,
  completedByRestaurantAt,
  deliveredAt,
  dropoffDeadline,
}: Pick<Order, 'completedAt' | 'completedByRestaurantAt' | 'deliveredAt' | 'dropoffDeadline'>) {
  const completionDate = deliveredAt ?? completedByRestaurantAt ?? completedAt;
  return new Date(completionDate ?? dropoffDeadline);
}

export function getPurchaseTotalAmounts(items: StatementPurchaseAmounts[], options?: { isRounded?: boolean }) {
  return getTotalAmounts(items, STATEMENT_PURCHASE_AMOUNT_KEYS, options);
}

export function getTotalOrder(order: Order) {
  const { customerInvoicingData, totalDiscountsRestaurant, totalDiscountsSmood } = order;

  if (customerInvoicingData) {
    return customerInvoicingData?.totals.total.TTC;
  }

  const deductions = toCentimes(totalDiscountsRestaurant + totalDiscountsSmood);
  const totalBeforeDeductions = getTotalOrderBeforeDeductions(order);

  return Math.max(totalBeforeDeductions - deductions, 0);
}

export function getTotalOrderBeforeDeductions({ tips, totalOrder, totalOrderWithTips }: Order) {
  const driverTips = toCentimes(tips?.driver?.amount);
  const total = toCentimes(totalOrder);
  const totalWithTips = toCentimes(totalOrderWithTips);

  return driverTips && totalWithTips === total + driverTips ? totalWithTips : total;
}

export function isAuthorized(permissions: PermissionsResource, scope: PermissionsScope, action: PermissionsAction) {
  if (permissions.values?.[scope]?.[action]) {
    return true;
  }

  if (permissions.name === ADMIN_PERMISSIONS_NAME) {
    const adminScope = getAdminPermissionsScope(scope);

    if (adminScope && permissions.values?.[adminScope]?.[action]) {
      return true;
    }
  }

  return false;
}
