import { FileExtension, getDataUrl, isObjectLitteral, safeJSONParse } from '@sbiz/util-common';

import { ApiError } from './ApiError';
import { Console } from './Console';
import { StorageCache, StorageType } from './types';

const STORAGE_CACHE: StorageCache = { local: new Map(), session: new Map() };

export function clearStorage(type: StorageType) {
  if (isInBrowser(`Trying to clear the browser's ${type} storage`)) {
    getStorage(type).clear();
  }
}

export function clearStorages() {
  clearStorage('session');
  clearStorage('local');
}

export function downloadFile(content: string, extension: FileExtension, filename?: string) {
  const anchor = document.createElement('a');

  const dataUrl = getDataUrl(content, extension);
  anchor.setAttribute('href', dataUrl);

  anchor.setAttribute('download', getDownloadName(extension, filename));

  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
}

function getDownloadName(extension: FileExtension, filename?: string) {
  if (!filename) {
    return `export.${extension}`;
  }

  return filename.endsWith(`.${extension}`) ? filename : `${filename}.${extension}`;
}

export function downloadCsv(csvData: string, filename?: string) {
  return downloadFile(csvData, 'csv', filename);
}

export function downloadPdf(pdfData: string, filename?: string) {
  return downloadFile(pdfData, 'pdf', filename);
}

export function downloadXlsx(xlsxData: string, filename?: string) {
  return downloadFile(xlsxData, 'xlsx', filename);
}

export function getStorage(type: StorageType = 'session') {
  const message = "Trying to access the browser's storage";

  if (isInBrowser(message)) {
    return type === 'local' ? localStorage : sessionStorage;
  }

  throw new Error(message);
}

export function getStorageItem<T>(key: string, type: StorageType = 'session') {
  if (!isInBrowser(`Trying to access the browser's storage - key: '${key}'`)) {
    return undefined;
  }

  const storage = getStorage(type);
  const cache = STORAGE_CACHE[type];

  const serialized = storage.getItem(key);
  if (!serialized) {
    return undefined;
  }

  const cached = cache.get(key);
  const parsed = safeJSONParse<T>(serialized);

  if (isObjectLitteral(parsed) && cached?.serialized === serialized) {
    return cached.parsed as T;
  }

  cache.set(key, { serialized, parsed });
  return parsed as T;
}

export function getStorageObject<T extends object>(key: string, type?: StorageType) {
  const storageItem = getStorageItem<T>(key, type);
  return isObjectLitteral(storageItem) ? storageItem : null;
}

export function removeStorageItem(key: string, type: StorageType = 'session') {
  if (isInBrowser(`Trying to remove a key from the browser's storage - key: '${key}'`)) {
    const storage = getStorage(type);
    const cache = STORAGE_CACHE[type];

    storage.removeItem(key);
    cache.delete(key);
  }
}

export function setStorageItem(key: string, value: unknown, type?: StorageType) {
  if (isInBrowser(`Trying to edit a key in the browser's storage - key: '${key}', value:${value}`)) {
    getStorage(type).setItem(key, JSON.stringify(value));
  }
}

export function getLocalStorageItem<T>(key: string) {
  return getStorageItem<T>(key, 'local');
}

export function getWindow(message?: string) {
  if (globalThis.window) {
    return globalThis.window;
  }

  Console.warn(message ? `Server-side: ${message}` : 'Trying to access window server-side');
}

export function isInBrowser(message?: string) {
  return Boolean(getWindow(message));
}

export function removeLocalStorageItem(key: string) {
  removeStorageItem(key, 'local');
}

export function setLocalStorageItem(key: string, value: unknown) {
  setStorageItem(key, value, 'local');
}

export function getSessionStorageItem<T>(key: string) {
  return getStorageItem<T>(key);
}

export function isApiError(error: unknown): error is ApiError {
  return error instanceof ApiError;
}

export function removeSessionStorageItem(key: string) {
  removeStorageItem(key);
}

export function setSessionStorageItem(key: string, value: unknown) {
  setStorageItem(key, value, 'session');
}

export { ApiError, Console };
